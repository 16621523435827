<template>
  <MarketingPage :entry="entry" :social-media-accounts="socialMediaAccounts" />
</template>
<script>
import gql from 'graphql-tag'
import entryPage, { SeomaticFragment, EntryPageFragment } from '@/mixins/entryPage'
import MarketingPage, { HomePageFragment } from '@/components/marketing/MarketingPage'
import { SocialMediaAccountsFragment } from '@/components/layout/footer/SocialMediaAccounts'

export const HomePageQuery = gql`
  query HomePageQuery($site: String!, $uri: String!) {
    entry(section: "home", site: [$site]) {
      ...EntryPageFragment
      ...HomePageFragment
    }

    socialSet: globalSet(site: [$site], handle: "socialMediaAccounts") {
      ...SocialMediaAccountsFragment
    }

    seomatic(site: $site, uri: $uri, asArray: true) {
      ...SeomaticFragment
    }
  }

  ${EntryPageFragment}
  ${HomePageFragment}
  ${SeomaticFragment}
  ${SocialMediaAccountsFragment}
`

export default {
  name: 'HomePageController',
  components: { MarketingPage },
  mixins: [entryPage],
  async asyncData({ $craftRequest, $retourRedirect, app, params, error, route }) {
    const { locale } = app.i18n
    const uri = '__home__' // home uri by craft convention
    const variables = { site: locale, uri }
    const {
      entry,
      seomatic,
      socialSet: { socialMediaAccounts }
    } = await $craftRequest(HomePageQuery, variables)
    if (entry === null) {
      return $retourRedirect()
    }
    return { entry, seomatic, socialMediaAccounts }
  },
  data() {
    return { socialMediaAccounts: [] }
  },
  mounted() {
    this.setBreadCrumb()
    this.gtmPushPageMeta()
  },
  methods: {
    buildBreadCrumb() {
      return []
    }
  }
}
</script>
