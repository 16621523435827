<template>
  <div>
    <MarketingBlocks :blocks="entry.marketingBlocks" :social-media-accounts="socialMediaAccounts" />
  </div>
</template>
<script>
import gql from 'graphql-tag';
import MarketingBlocks, { MarketingBlocksMatrixFragment } from '@/components/marketing/marketingBlocks/MarketingBlocks';

export const MarketingPageFragment = gql`
  fragment MarketingPageFragment on marketing_marketing_Entry {
    marketingBlocks {
      ...MarketingBlocksMatrixFragment
    }
    seoTopics
  }
  ${MarketingBlocksMatrixFragment}
`;
export const HomePageFragment = gql`
  fragment HomePageFragment on home_home_Entry {
    marketingBlocks {
      ...MarketingBlocksMatrixFragment
    }
    seoTopics
  }
  ${MarketingBlocksMatrixFragment}
`;

export default {
  name: 'MarketingPage',
  components: {
    MarketingBlocks,
  },
  props: {
    entry: {
      type: Object,
      required: true,
    },
    socialMediaAccounts: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
